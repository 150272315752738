import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'primereact/chart';
import { BaseApiService } from '../../utils/BaseApiService';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { convertDateFormat, convertTo24HourFormat, datesAreEqual, formatDate, formatNumberWithCommas, toReadableTime } from '../../utils/Utils';
import StatusSummary from '../../components/StatusSummary';
import { Skeleton } from 'primereact/skeleton';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SHOPS_ENDPOINT } from '../../utils/EndpointsUtils';

const ShopOwnerDashboard = () => {
    const calendarRef = useRef(null);

    let times24hr = [];
    for (let hour = 7; hour <= 24; hour++) {
        times24hr.push(`${hour.toString().padStart(2, '0')}:00`);
    }

    let shopIncomeDataSets = [];
    let shopTransactionDataSets = [];

    let shopHourlySales = [];
    let dailyLabels = [];

    let colors = ['#f5e102', '#aba490', '#000000'];

    const initHourlyData = {
        labels: times24hr,
        datasets: shopHourlySales
    };

    const initWeeklyData = {
        labels: dailyLabels,
        datasets: shopIncomeDataSets
    };

    const initWeeklyTxnData = {
        labels: dailyLabels,
        datasets: shopTransactionDataSets
    };
    const [shops, setShops] = useState([]);

    const [isLoading, setisloading] = useState(true);
    const [weekSales, setWeekSales] = useState([]);
    const [todaySales, setTodaySales] = useState([]);
    const [weekDebtSales, setWeekDebtSales] = useState([]);

    const [totalSales, setTotalSales] = useState(0);
    const [weeklyIncomeData, setWeeklyIncomeData] = useState(initWeeklyData);
    const [hourlyData, setHourlydata] = useState(initHourlyData);
    const [weeklyTxnData, setWeeklyTxnData] = useState(initWeeklyTxnData);
    const [income, setIncome] = useState(0);
    const [capital, setCapital] = useState(0);
    const [purchases, setPurchases] = useState(0);
    const [weeklyPurchaseData, setWeeklyPurchaseData] = useState(null);

    const [today, setToday] = useState(new Date());

    const [datesOfWeek, setDatesOfWeek] = useState([]);
    const [selectedDay, setSelectedDay] = useState(formatDate(new Date(), false));

    const fetchShops = async () => {
        let searchParameters = { offset: 0, limit: 0 };

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        new BaseApiService(SHOPS_ENDPOINT)
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setShops(response.records);
                const offersDebt = [...response.records].some((shop) => shop?.supportsCreditSales === true);
                UserSessionUtils.setOffersDebt(offersDebt);
                UserSessionUtils.setOwnerShopIds(response.records.map((shop) => shop.id));
            })
            .catch((error) => {
                setisloading(false);
            });
    };

    const getSaleChartData = () => {
        if (weekSales.length > 0) {
            const cashTransactions = weekSales?.filter((sale) => sale.balanceGivenOut >= 0);
            shops.forEach((shop, index) => {
                let incomeValues = [];

                let txns = [];
                let currency = '';

                //setting data for the daily income
                for (let date of datesOfWeek) {
                    //filtering out sales for the current loop date
                    let dateSales = [...cashTransactions].filter((sale) => {
                        return datesAreEqual(date, new Date(sale.soldOnDate));
                    });

                    txns.push(dateSales?.length);
                    let shopSales = dateSales.filter((sale) => sale?.shopId === shop.id);
                    //profits array for the date
                    const profits = shopSales.map((item) => item.lineItems?.reduce((a, i) => a + i.totalProfit, 0) || 0).filter((profit) => profit !== undefined);
                    let income = Math.round(profits.reduce((a, b) => a + b, 0)); //getting the sum profit in all carts
                    currency = shopSales[0]?.currency;
                    incomeValues.push(income);

                    if (datesAreEqual(date, new Date())) {
                        setTodaySales(dateSales);
                    }
                }

                shopIncomeDataSets.push({
                    label: shop?.name,
                    data: incomeValues,
                    backgroundColor: colors[index],
                    tension: 0.4,
                    txns: txns,
                    currency: currency
                });
            });
            let newData = {
                ...weeklyIncomeData,
                datasets: shopIncomeDataSets,
                labels: [...new Set(datesOfWeek.map((date) => formatDate(date, false)))]
            };
            setWeeklyIncomeData(newData);
            setisloading(false);
        } else {
            setisloading(false);
        }
    };

    const getTxnChartData = () => {
        if (weekSales.length > 0) {
            let cash = [];
            let debt = [];

            let currency = '';

            for (let date of datesOfWeek) {
                const dateFilterCallBack = (sale) => datesAreEqual(date, new Date(sale.soldOnDate));

                const cashTransactions = weekSales?.filter((sale) => sale.balanceGivenOut >= 0).filter(dateFilterCallBack);

                const debtTransactions = weekSales?.filter((sale) => sale.balanceGivenOut < 0).filter(dateFilterCallBack);

                cash.push(cashTransactions.length);
                debt.push(debtTransactions.length);
            }

            shopTransactionDataSets.push(
                {
                    label: 'Cash',
                    data: cash,
                    backgroundColor: colors[0],
                    tension: 0.4,
                    currency: currency
                },
                {
                    label: 'Debt',
                    data: debt,
                    backgroundColor: '#000',
                    tension: 0.4,
                    currency: currency
                }
            );
            let newData = {
                ...weeklyTxnData,
                datasets: shopTransactionDataSets,
                labels: [...new Set(datesOfWeek.map((date) => formatDate(date, false)))]
            };
            setWeeklyTxnData(newData);
            setisloading(false);
        } else {
            setisloading(false);
        }
    };

    const getWeekSales = () => {
        const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
        setisloading(true);
        // Calculate the date of the previous Saturday
        const saturday = new Date(today);
        saturday.setDate(today.getDate() - dayOfWeek - 1); // Subtracting 1 to get the previous Saturday
        const sunday = new Date(today);
        sunday.setDate(today.getDate() - dayOfWeek);
        datesOfWeek.length = 0;
        for (let i = 0; i < 7; i++) {
            const date = new Date(sunday);
            date.setDate(sunday.getDate() + i);
            if (!datesOfWeek.includes(date)) {
                datesOfWeek.push(date);
            }
        }

        let searchParameters = {
            offset: 0,
            limit: 0,
            shopOwnerId: UserSessionUtils.getShopOwnerId(),
            startDate: convertDateFormat(saturday),
            endDate: convertDateFormat(datesOfWeek[datesOfWeek.length - 1], true)
        };

        new BaseApiService('/shop-sales')
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
                setWeekSales(response.records);
                setCapital(Math.floor(response?.totalPurchaseCost));
                setIncome(Math.floor(response?.totalProfit));
                setTotalSales(response.totalItems);
            })
            .catch((error) => {
                setisloading(false);
            });
    };

    const getWeekStockEntries = () => {
        const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
        setisloading(true);
        // Calculate the date of the previous Saturday
        const saturday = new Date(today);
        saturday.setDate(today.getDate() - dayOfWeek - 1); // Subtracting 1 to get the previous Saturday
        const sunday = new Date(today);
        sunday.setDate(today.getDate() - dayOfWeek);
        datesOfWeek.length = 0;
        for (let i = 0; i < 7; i++) {
            const date = new Date(sunday);
            date.setDate(sunday.getDate() + i);
            if (!datesOfWeek.includes(date)) {
                datesOfWeek.push(date);
            }
        }

        const searchParameters = {
            offset: 0,
            limit: 0,
            shopOwnerId: UserSessionUtils.getShopOwnerId(),
            startDate: convertDateFormat(sunday),
            endDate: convertDateFormat(datesOfWeek[datesOfWeek.length - 1], true)
        };

        new BaseApiService('/stock-entries')
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
                const labels = [...new Set(datesOfWeek.map((date) => formatDate(date, false)))];
                let purchasesData = [];
                for (let date of datesOfWeek) {
                    let datePurchases = [...response?.records].filter((item) => {
                        return datesAreEqual(date, new Date(item?.stockedOnDate));
                    });

                    purchasesData.push(datePurchases.reduce((a, b) => a + b?.purchasePrice, 0));
                }
                setWeeklyPurchaseData({
                    labels: labels,
                    datasets: [
                        {
                            label: 'Stock entry',
                            data: purchasesData,
                            //
                            backgroundColor: colors[0],
                            tension: 0.4
                        }
                    ]
                });

                setPurchases(response?.records);
            })
            .catch((error) => {
                setisloading(false);
            });
    };

    const handleSalesPerHour = () => {
        if (todaySales.length > 0) {
            shops.forEach((shop, index) => {
                let salesPerHour = [];
                let shopSales = todaySales.filter((sale) => sale?.shopId === shop.id);
                let incomeValues = [];

                times24hr.forEach((hour) => {
                    let hourSales = shopSales?.filter((sale) => {
                        const saleTime = convertTo24HourFormat(toReadableTime(sale?.dateCreated));
                        const isSimilarHour = saleTime.split(':')[0] === hour.split(':')[0];
                        return isSimilarHour;
                    });
                    salesPerHour.push(hourSales.length);

                    //profits array for the date
                    const profits = hourSales.map((item) => item.lineItems?.reduce((a, i) => a + i.totalProfit, 0) || 0).filter((profit) => profit !== undefined);
                    let income = Math.round(profits.reduce((a, b) => a + b, 0)); //getting the sum profit in all carts
                    incomeValues.push(income);
                });

                shopHourlySales.push({
                    label: shop?.name,
                    data: salesPerHour,
                    borderColor: colors[index],
                    backgroundColor: colors[index],
                    tension: 0.6,
                    borderWidth: 2.5,
                    incomeValues: incomeValues,
                    currency: shopSales[0]?.currency
                });
            });
            let newHourlyData = {
                ...hourlyData,
                datasets: shopHourlySales
            };
            setHourlydata(newHourlyData);
            setisloading(false);
        }
    };

    const changeTodaySales = (label = '') => {
        const filteredSales = weekSales.filter((sale) => label === formatDate(new Date(sale?.soldOnDate), false));
        setTodaySales(filteredSales);
        setSelectedDay(label);
    };

    const lineChartOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        const value = context.parsed.y;
                        const income = context.dataset.incomeValues[context?.dataIndex];
                        const currency = context.dataset?.currency || '';

                        if (label) {
                            label += ': ';
                        }

                        if (context.parsed.y !== null) {
                            label += `Income ${currency} ${formatNumberWithCommas(income)}, Txns ${value}`;
                        }
                        return label;
                    }
                }
            }
        }
    };

    const barChartOptions = {
        onClick: (evt, element) => {
            if (element.length > 0) {
                let index = element[0].index;
                let label = weeklyIncomeData.labels[index];
                changeTodaySales(label);
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        const value = context.parsed.y;
                        const txns = context.dataset.txns[context?.dataIndex];
                        const currency = context.dataset?.currency || '';

                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `Income ${currency} ${formatNumberWithCommas(value)}, Txns ${txns}`;
                        }
                        return label;
                    }
                }
            }
        }
    };

    useEffect(() => {
        fetchShops();
    }, []);

    useEffect(() => {
        getWeekSales();
        getWeekStockEntries();
    }, [today]);

    useEffect(() => {
        getSaleChartData();
        getTxnChartData();
    }, [weekSales]);

    useEffect(() => {
        handleSalesPerHour();
    }, [todaySales]);

    return (
        <div className="layout-dashboard">
            <div className="py-2">
                <div className="grid">
                    <StatusSummary iconName="pi pi-shopping-cart " title="Sales" value={formatNumberWithCommas(totalSales)} />
                    <StatusSummary title="Purchases" value={formatNumberWithCommas(Math.round(purchases?.length))} iconName="pi pi-money-bill" />
                    <StatusSummary title="Income" value={formatNumberWithCommas(income)} iconName="bx bx-receipt" />
                    <StatusSummary title="Capital" value={formatNumberWithCommas(capital)} iconName="bx bx-wallet" />
                </div>
            </div>

            <div className="grid p-fluid">
                <div className="col-12 lg:col-6">
                    <div className="card h-full">
                        {isLoading ? (
                            <div>
                                <Skeleton height="3rem" className="mb-2" />

                                <Skeleton height="2rem" className="mb-2" />

                                <Skeleton height="22rem" className="mb-2" />
                            </div>
                        ) : (
                            <div>
                                <div className="flex gap-2 justify-content-between">
                                    <div className="flex gap-2 justify-content-between">
                                        <div>
                                            <label className="centerText title font-semibold">Daily</label>
                                            <br />
                                            <label className="">Income</label>
                                        </div>
                                        <InputText className="border-none" value={formatDate(today)} readOnly />
                                        <Calendar readOnlyInput className="border-none hidden" ref={calendarRef} maxDate={new Date()} dateFormat="dd, M yy" value={today} placeholder="Select weeks day" onChange={(e) => setToday(e.value)} />
                                    </div>

                                    <Button icon={'pi pi-calendar'} className="transparent-btn" onClick={() => calendarRef?.current?.show()} />
                                </div>
                                <Chart type="bar" data={weeklyIncomeData} options={barChartOptions} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-12 lg:col-6">
                    <div className="card h-full">
                        {isLoading ? (
                            <div>
                                <Skeleton height="3rem" className="mb-2" />

                                <Skeleton height="2rem" className="mb-2" />

                                <Skeleton height="22rem" className="mb-2" />
                            </div>
                        ) : (
                            <div>
                                <label className="centerText title font-semibold">Hourly</label>
                                <br />
                                <label className="">Transactions {selectedDay}</label>
                                <Chart type="line" data={hourlyData} options={lineChartOptions} />
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-12 lg:col-6">
                    <div className="card h-full">
                        <label className="centerText title font-semibold">Weekly Transactions</label>

                        <Chart type="bar" data={weeklyTxnData} />
                    </div>
                </div>

                <div className="col-12 lg:col-6">
                    <div className="card h-full">
                        <label className="centerText title font-semibold">Weekly Purchases</label>

                        <Chart type="bar" data={weeklyPurchaseData} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShopOwnerDashboard;
