import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router';
import { formatNumberWithCommas, toReadableDate } from '../../utils/Utils';

const ShopDetail = () => {
    const { state } = useLocation();
    const { name, id, initialCapital, ownerName, physicalLocation, registrationNumber, locationLatitude, locationLongitude, dateCreated } = state;
    const toast = useRef(null);

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="px-2 py-2 md:px-2 lg:px-2">
                    <div className="grid">
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="surface-card shadow-2 p-3 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-900 font-medium  text-xl mb-3">{name}</span>
                                        <div className="text-900 font-medium text-xl">{id}</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                        <i className="pi pi-shopping-cart text-blue-500 text-xl"></i>
                                    </div>
                                </div>
                                <span className="text-green-500 font-medium">24 new </span>
                                <span className="text-500">since last visit</span>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="surface-card shadow-2 p-3 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-900 font-medium  text-xl mb-3">Available Capital</span>
                                        <div className="text-900 font-medium text-xl">$2.100</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                        <i className="pi pi-map-marker text-orange-500 text-xl"></i>
                                    </div>
                                </div>
                                <span className="text-green-500 font-medium">%52+ </span>
                                <span className="text-500">since last week</span>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="surface-card shadow-2 p-3 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-900 font-medium  text-xl mb-3">Total products</span>
                                        <div className="text-900 font-medium text-xl">28441</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                        <i className="pi pi-inbox text-cyan-500 text-xl"></i>
                                    </div>
                                </div>
                                <span className="text-green-500 font-medium">520 </span>
                                <span className="text-500">newly registered</span>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 lg:col-3">
                            <div className="surface-card shadow-2 p-3 border-round">
                                <div className="flex justify-content-between mb-3">
                                    <div>
                                        <span className="block text-900 font-medium  text-xl mb-3">Stock value</span>
                                        <div className="text-900 font-medium text-xl">152 Unread</div>
                                    </div>
                                    <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                                        <i className="pi pi-comment text-purple-500 text-xl"></i>
                                    </div>
                                </div>
                                <span className="text-green-500 font-medium">85 </span>
                                <span className="text-500">responded</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <Toast ref={toast} />

                    <div className="col-12">
                        <div className="grid">
                            <div className="col-12 md:col-12 lg:col-1">
                                <div className="flex justify-content-between">
                                    <img src="assets/demo/images/blocks/logos/Duqact Logo-12.png" alt="shopicon" className="shop-image" />
                                </div>
                            </div>

                            <div className="col-12 md:col-12 lg:col-10">
                                <h2 className="ml-1">{name}</h2>

                                <div className="field">
                                    <div className="grid col-12">
                                        <div className="col-12 md:col-6 lg:col-2">
                                            <label className="mt-3">
                                                <span className="font-medium">Owner:</span>
                                                <br /> {ownerName}
                                            </label>
                                        </div>

                                        <div className="col-12 md:col-6 lg:col-2">
                                            <label className="mt-3">
                                                <span className="font-medium"> Location</span>
                                                <br /> <>{physicalLocation}</>
                                            </label>
                                        </div>

                                        <div className="col-12 md:col-6 lg:col-2">
                                            <label className="mt-3">
                                                <span className="font-medium">Registration no:</span>
                                                <br /> <>{registrationNumber}</>
                                            </label>
                                        </div>

                                        <div className="col-12 md:col-6 lg:col-2">
                                            <label className="mt-3">
                                                <span className="font-medium">Capital:</span>
                                                <br /> <>{formatNumberWithCommas(initialCapital)}</>
                                            </label>
                                        </div>
                                        <div className="col-12 md:col-6 lg:col-2">
                                            <label className="mt-3">
                                                <span className="font-medium"> Created on: </span>
                                                <br /> <>{toReadableDate(dateCreated)}</>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShopDetail;
