import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { PrimeIcons } from 'primereact/api';
import PhoneInput from 'react-phone-input-2';
import { MultiSelect } from 'primereact/multiselect';

import { BaseApiService } from '../../utils/BaseApiService';
import { useNavigate } from 'react-router-dom';
import StorageParams from '../../constants/StorageParams';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Dropdown } from 'primereact/dropdown';
import { NumericFormat } from 'react-number-format';
import { Messages } from 'primereact/messages';
import { formatNumberWithCommas, toReadableDateTime, toReadableTime } from '../../utils/Utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Paginator } from 'primereact/paginator';
import StatusSummary from '../../components/StatusSummary';

const Manufacturer = () => {
    let initialManufacturer = {
        id: 0,
        serialNumber: null,
        name: null,
        postalAddress: null,
        physicalLocation: null,
        countryId: 0,
        countryName: null,
        phone: null,
        emailAddress: null,
        website: null,
        totalDistributors: 0,
        totalProducts: 0
    };

    const [manufacturers, setManufacturers] = useState(null);
    const [manufacturerDialog, setManufacturerDialog] = useState(false);
    const [deleteManufacturerDialog, setDeleteManufacturerDialog] = useState(false);
    const [deleteManufacturersDialog, setDeleteManufacturersDialog] = useState(false);
    const [manufacturer, setManufacturer] = useState(initialManufacturer);
    const [selectedManufacturers, setSelectedManufacturers] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [edit, setEdit] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [countries, setCountries] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [altphoneNumber, setAltPhoneNumber] = useState(null);

    const [distributors, setDistributors] = useState(null);
    const [selectedDistributor, setSelectedDistributor] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [limit, setLimit] = useState(0);

    let offset = 0;
    const dialogMessage = useRef();
    const navigate = useNavigate();
    const toast = useRef(null);
    const dt = useRef(null);

    const fetchCountries = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };
        new BaseApiService('/lookups/countries')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setCountries(response.records);
            })
            .catch((error) => {});
    };

    const fetchManufacturers = async () => {
        setIsLoading(true);
        let searchParameters = { offset: offset, limit: MAXIMUM_RECORDS_PER_PAGE, searchTerm: searchTerm };

        new BaseApiService('/manufacturers')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setManufacturers(response.records);
                setTotalRecords(response.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {});
    };
    const fetchDistributors = async () => {
        let searchParameters = { offset: 0, limit: 0 };
        setIsLoading(true);
        new BaseApiService('/distributors')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setDistributors(response.records);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };
    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchManufacturers();
    };

    useEffect(() => {
        fetchCountries();
        fetchDistributors();
    }, []);

    useEffect(() => {
        fetchManufacturers();
    }, [searchTerm]);

    const openNew = () => {
        setManufacturer(initialManufacturer);
        setSubmitted(false);
        setManufacturerDialog(true);
        setEdit(false);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setManufacturerDialog(false);
    };

    const hideShopDialog = () => {
        setDeleteManufacturerDialog(false);
    };

    const hideDeleteShopsDialog = () => {
        setDeleteManufacturersDialog(false);
    };

    const saveManufacturer = () => {
        setSubmitted(true);
        setIsLoading(true);
        const apiUrl = edit && edit === true ? '/manufacturers/' + manufacturer.id : '/manufacturers';

        new BaseApiService(apiUrl)
            .saveRequestWithJsonResponse(
                {
                    name: manufacturer?.name,
                    postalAddress: manufacturer?.postalAddress,
                    physicalLocation: manufacturer?.physicalLocation,
                    phone: manufacturer?.phone,
                    emailAddress: manufacturer?.emailAddress,
                    website: manufacturer?.website,
                    countryId: selectedCountry?.id
                },
                edit
            )
            .then((response) => {
                if (edit && edit === true) {
                    setRefresh(true);
                } else {
                    let _manufacturers = [...manufacturers];

                    let _manufacturer = { ...response };

                    _manufacturers.push(_manufacturer);

                    setManufacturers(_manufacturers);
                }
                setManufacturerDialog(false);
                setManufacturer(initialManufacturer);
                setIsLoading(false);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Manufacturer Details saved', life: 3000 });
            })
            .catch((error) => {
                showErrorMessage(dialogMessage, error.message);
                setIsLoading(false);
            });
    };

    const editManufacturer = (manufacturer) => {
        setEdit(true);

        setManufacturer({ ...manufacturer });
        setSelectedCountry(countries.find((obj) => obj.id === manufacturer.countryId));
        setManufacturerDialog(true);
    };

    const confirmDeleteManufacturer = (manufacturer) => {
        setManufacturer(manufacturer);
        setDeleteManufacturerDialog(true);
    };

    const deleteManufacturer = () => {
        new BaseApiService('/manufacturers/' + manufacturer.id)
            .deleteRequestWithJsonResponse()
            .then((response) => {
                let _manufacturers = manufacturers.filter((val) => val.id !== manufacturer.id);
                setManufacturers(_manufacturers);
                setDeleteManufacturerDialog(false);
                setManufacturer(initialManufacturer);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Manufacturer Deleted', life: 3000 });
            })
            .catch((error) => {});
    };

    const confirmDeleteSelected = () => {
        setDeleteManufacturersDialog(true);
    };

    const deleteManufacturers = () => {
        let _manufacturers = manufacturers.filter((val) => !selectedManufacturers.includes(val));
        setManufacturers(_manufacturers);
        setDeleteManufacturersDialog(false);
        setSelectedManufacturers(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Manufacturers Deleted', life: 3000 });
    };

    const onInputChange = (e, name) => {
        if (name == 'phone') {
            const val = (e) || '';
            let _manufacturer = {...manufacturer};
            _manufacturer[`${name}`] = val;
            setManufacturer(_manufacturer);
        }else {
            const val = (e.target && e.target.value) || '';
            let _manufacturer = { ...manufacturer };
            _manufacturer[`${name}`] = val;

            setManufacturer(_manufacturer);
        }
    };

    const onCountryChange = (e) => {
        setSelectedCountry(e.target.value);
    };

    const onDistributorChange = (e) => {
        setSelectedDistributor(e.target.value);
    };

    const serialNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Serial number</span>
                {rowData.serialNumber}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
                <br />
                <span className="text-sm opacity-80">SN: {rowData.serialNumber}</span>
            </>
        );
    };

    const postalAddressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Postal address</span>
                {rowData.postalAddress}
            </>
        );
    };

    const physicalLocationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Physical location</span>
                {rowData.physicalLocation}
            </>
        );
    };

    const countryDetailsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Country</span>
                {rowData.countryName}
            </>
        );
    };

    const phoneNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Phone</span>
                {rowData.phone}
            </>
        );
    };

    const emailAddressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email Address</span>
                {rowData.emailAddress}
            </>
        );
    };

    const websiteBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Website</span>
                {rowData.website}
            </>
        );
    };

    const createdByFullNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Created by</span>
                {rowData.createdByFullName}
            </>
        );
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date Created</span>
                {toReadableDateTime(rowData.dateCreated)}
            </>
        );
    };

    const totalDistributorsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Distributors</span>
                <Button
                    label={checkNumericAttribute(rowData.totalDistributors) + ''}
                    style={{ color: '#495057' }}
                    className="p-button-link numlink"
                    // onClick={() =>
                    //     navigate('/administrator/shopOwner/shops', {
                    //         state: {
                    //             // ownerId: rowData.id,
                    //             // ownerName: rowData.firstName + ' ' + rowData.lastName
                    //         }
                    //     })
                    // }
                />
            </>
        );
    };

    const totalProductsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Products</span>
                <Button
                    label={checkNumericAttribute(rowData.totalProducts) + ''}
                    style={{ color: '#495057' }}
                    className="p-button-link numlink"
                    // onClick={() =>
                    //     navigate('/administrator/shopOwner/shops', {
                    //         state: {
                    //             // ownerId: rowData.id,
                    //             // ownerName: rowData.firstName + ' ' + rowData.lastName
                    //         }
                    //     })
                    // }
                />
            </>
        );
    };

    function checkNumericAttribute(value) {
        if (value === null || typeof value === 'undefined') {
            return 0;
        } else {
            return value;
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex md:flex-row md:align-items-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success" onClick={() => editManufacturer(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger mt-2" onClick={() => confirmDeleteManufacturer(rowData)} />
            </div>
        );
    };
    function removeDuplicatesByKey(arr, key) {
        const seen = new Set();
        return arr.filter((item) => {
            const value = item[key];
            if (!seen.has(value)) {
                seen.add(value);
                return true;
            }
            return false;
        });
    }
    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Manufacturers</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchManufacturers} />
                </div>
            </span>
            <div className="my-2 ml-auto">
                <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} style={selectedManufacturers && selectedManufacturers.length > 1 ? { display: 'inline-flex' } : { display: 'none' }} />
            </div>
        </div>
    );

    const manufacturerDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" style={{ color: 'black', border: '1px solid black' }} onClick={hideDialog} />
            <Button loading={isLoading} label="Save" icon="pi pi-check" className="p-button-text" style={{ color: '#FFEB3B', border: '1px solid black', backgroundColor: 'black' }} onClick={saveManufacturer} />
        </>
    );
    const deleteManufacturerDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideShopDialog} />
            <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteManufacturer} />
        </>
    );
    const deleteManufacturersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideDeleteShopsDialog} />
            <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteManufacturers} />
        </>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} manufacturers
                </span>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Total" value={formatNumberWithCommas(totalRecords)} />
                        <StatusSummary title="Male" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Female" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Total" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div>
                </div>
                <div className="py-2 -mt-3">
                    <Accordion>
                        <AccordionTab className="p-fluid" header="Filter manufacturer">
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Price range</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Amount range</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Batch no</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                            </div>

                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Purchase date</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Purchase by</label>
                                    <InputNumber id="purchasePrice" />
                                </div>
                                <div className="field col">
                                    <label htmlFor="purchasePrice">Expiry date</label>
                                    <Calendar />
                                </div>
                            </div>
                            <div className="formgrid grid">
                                <div className="field col">
                                    <label htmlFor="purchasedQuantity">Category</label>
                                    <InputNumber id="purchasedQuantity" />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <Button label="Clear" icon="pi pi-times" className="p-button-text " style={{ color: 'black', border: '1px solid black', margin: '5px', width: 'fit-content' }} />
                                <Button label="Filter" icon="pi pi-check" className="p-button-text" style={{ color: '#FFEB3B', border: '1px solid black', backgroundColor: 'black', margin: '5px', width: 'fit-content' }} />
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        ref={dt}
                        loading={isLoading}
                        value={manufacturers}
                        selection={selectedManufacturers}
                        onSelectionChange={(e) => setSelectedManufacturers(e.value)}
                        dataKey="id"
                        rows={10}
                        className="datatable-responsive"
                        globalFilter={globalFilter}
                        emptyMessage="No manufacturer found."
                        header={header}
                        size="small"
                        resizableColumns
                    >
                        <Column field="name" header="Name" body={nameBodyTemplate} className="text-left" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'left' }} />
                        <Column field="country" header="Country" body={countryDetailsBodyTemplate} className="text-left" headerStyle={{ width: '15%', minWidth: '10rem', textAlign: 'left' }} />
                        <Column field="phone" header="Phone" body={phoneNumberBodyTemplate} className="text-center" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'center' }} />
                        <Column field="distributors" header="Distributors" body={totalDistributorsBodyTemplate} className="text-center" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'center' }} />
                        <Column field="products" header="Products" body={totalProductsBodyTemplate} className="text-center" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'center' }} />
                        <Column field="createdBy" header="Created by" body={createdByFullNameBodyTemplate} className="text-left" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'left' }} />
                        <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} className="text-center" headerStyle={{ width: '15%', minWidth: '10rem', textAlign: 'center' }} />
                        <Column body={actionBodyTemplate} header="Actions" />
                    </DataTable>
                    <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} />

                    <Dialog visible={manufacturerDialog} style={{ width: '700px' }} header="Manufacturer" modal className="p-fluid" footer={manufacturerDialogFooter} onHide={hideDialog}>
                        <Messages ref={dialogMessage} style={{ width: '100%' }} />
                        <div className="field" style={{ marginTop: '10px' }}>
                            <label htmlFor="country">Country</label>
                            <Dropdown id="country" value={selectedCountry} options={countries} onChange={onCountryChange} optionLabel={'name'} filter showClear filterBy="name" placeholder="Select Country" />
                            {submitted && !selectedCountry && <small className="p-invalid">Country is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Name</label>
                            <InputText id="name" value={manufacturer.name || ''} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !manufacturer.name })} />
                            {submitted && !manufacturer.name && <small className="p-invalid">Manufacturer name is required.</small>}
                        </div>
                        <div className="formgrid grid">
                            <div className="field col-12 md:col-4">
                                <label htmlFor="phone">Phone</label>
                                <PhoneInput
                                    id="primaryPhoneNumber"
                                    className={classNames({ 'country-code-reduced-further error-Input': submitted && !manufacturer.phone, 'country-code-reduced-further': true })}
                                    value={manufacturer.phone || ''}
                                    onChange={(e) => onInputChange(e, 'phone')}
                                    country={'ug'}
                                    enableSearch
                                    inputProps={{
                                        required: true
                                    }}
                                />
                                {submitted && !manufacturer.phone && <small className="p-invalid">Phone is required.</small>}
                            </div>

                            <div className="field col-6 md:col-4">
                                <label>Alternative phone</label>
                                <PhoneInput
                                    enableSearch
                                    id="altPhoneNumber"
                                    className={classNames({ 'country-code-reduced-further': true })}
                                    value={altphoneNumber || ''}
                                    onChange={(e) => setAltPhoneNumber(e)}
                                    country={'ug'}
                                    inputProps={{
                                        required: true
                                    }}
                                />
                            </div>
                            <div className="field col-6 md:col-4">
                                <label htmlFor="emailAddress">Email address</label>
                                <InputText
                                    id="emailAddress"
                                    value={manufacturer.emailAddress || ''}
                                    onChange={(e) => onInputChange(e, 'emailAddress')}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': submitted && !manufacturer.emailAddress })}
                                />
                                {submitted && !manufacturer.emailAddress && <small className="p-invalid">Email address is required.</small>}
                            </div>
                        </div>

                        <div className="formgrid grid">
                            <div className="field col-6 md:col-4">
                                <label htmlFor="postalAddress">Postal address</label>
                                <InputText
                                    id="postalAddress"
                                    value={manufacturer.postalAddress || ''}
                                    onChange={(e) => onInputChange(e, 'postalAddress')}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': submitted && !manufacturer.postalAddress })}
                                />
                                {submitted && !manufacturer.postalAddress && <small className="p-invalid">Postal address is required.</small>}
                            </div>
                            <div className="field col-6 md:col-4">
                                <label htmlFor="physicalLocation">Physical location</label>
                                <InputText
                                    id="physicalLocation"
                                    value={manufacturer.physicalLocation || ''}
                                    onChange={(e) => onInputChange(e, 'physicalLocation')}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': submitted && !manufacturer.physicalLocation })}
                                />
                                {submitted && !manufacturer.physicalLocation && <small className="p-invalid">Physical address is required.</small>}
                            </div>
                            <div className="field col-12 md:col-4">
                                <label htmlFor="website">Website</label>
                                <InputText id="website" value={manufacturer.website || ''} onChange={(e) => onInputChange(e, 'website')} required autoFocus className={classNames({ 'p-invalid': submitted && !manufacturer.website })} />
                                {submitted && !manufacturer.website && <small className="p-invalid">Website is required.</small>}
                            </div>
                        </div>
                        <div className="field">
                            <label>Distributor</label>
                            <Dropdown id="country" value={selectedDistributor} options={distributors} onChange={onDistributorChange} optionLabel={'companyOrBusinessName'} filter showClear filterBy="name" placeholder="Select Distributor" />
                            {submitted && !selectedDistributor && <small className="p-invalid">Distributor is required.</small>}{' '}
                        </div>
                        <div className="field">
                            <label htmlFor="remarks">Remarks</label>
                            <InputTextarea style={{ height: 100 }} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteManufacturerDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteManufacturerDialogFooter} onHide={hideShopDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {manufacturer && (
                                <span>
                                    Are you sure you want to delete <b>{manufacturer.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteManufacturersDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteManufacturersDialogFooter} onHide={hideDeleteShopsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {manufacturer && <span>Are you sure you want to delete the selected manufacturers?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
export default Manufacturer;
