import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { hasNull } from '../../utils/Utils';
import { BaseApiService } from '../../utils/BaseApiService';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';

import { InputTextarea } from 'primereact/inputtextarea';

export default function DeleteRecordDialog({ apiUrl, setIsLoading, selectedRecordName, deleteProductDialog, setDeleteProductDialog, onComplete, isLoading, showForm = false }) {
    const [reason, setReason] = useState(null);
    const [password, setPassword] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const hideProductDialog = () => {
        setDeleteProductDialog(false);
    };
    const dialogMessage = useRef();
    let payload = {
        reason,
        password
    };

    const deleteRecord = () => {
        setSubmitted(true);
        setIsLoading(true);

        new BaseApiService(apiUrl)
            .deleteRequestWithJsonResponse(payload)
            .then((response) => {
                setDeleteProductDialog(false);
                onComplete();
                setReason(null);
                setPassword(null);
                setIsLoading(false);
                setSubmitted(false);
            })
            .catch((error) => {
                setIsLoading(false);
                showErrorMessage(dialogMessage, error.message);
                setSubmitted(false);
            });
    };

    return (
        <Dialog closable={false} visible={deleteProductDialog} className={'p-fluid'} style={{ width: '450px' }} modal onHide={hideProductDialog}>
            <div className="flex align-items-center justify-content-center">
                <h5 className="font-bold">Confirm</h5>
            </div>
            <div className="field flex align-items-center justify-content-center">
                <span className="text-center">
                    Are you sure you want to delete <b>{selectedRecordName}</b>?
                </span>
            </div>
            <Messages ref={dialogMessage} style={{ width: '100%' }} />

            {showForm && (
                <>
                    <div className="field">
                        <label htmlFor="batchNumber">Reason</label>
                        <InputTextarea id="batchNumber" value={reason} onChange={(e) => setReason(e.target.value)} />
                        {submitted && !reason && <small className="p-error">Reason is required.</small>}
                    </div>
                    <div className="field">
                        <label htmlFor="expiryDate">Password</label>
                        <Password feedback={false} value={password} onChange={(e) => setPassword(e.target.value)} toggleMask />
                        {submitted && !password && <small className="p-error">Password is required.</small>}
                    </div>
                </>
            )}
            <div className="flex justify-content-between gap-3">
                <Button label="Cancel" icon="pi pi-times" className="outline-btn" onClick={hideProductDialog} />
                <Button label="Delete" icon="pi pi-trash" className="primary-btn" onClick={deleteRecord} loading={isLoading} />
            </div>
        </Dialog>
    );
}
