import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { BaseApiService } from '../../utils/BaseApiService';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { formatNumberWithCommas } from '../../utils/Utils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Paginator } from 'primereact/paginator';
import DeleteRecordDialog from '../../components/dialogs/DeleteRecordDialog';
import { SUBSCRIPTION_PLANS_ENDPOINT } from '../../utils/EndpointsUtils';
import SubscriptionPlanForm from 'components/dialogs/SubscriptionPlanForm';

const SubscriptionPlans = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [deleteRecordDialog, setDeleteRecordDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);

    let offset = 0;

    const dialogMessage = useRef();
    const toast = useRef(null);
    const dt = useRef(null);

    const fetchSubscriptions = async () => {
        setIsLoading(true);
        const searchParameters = {
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE
        };

        await new BaseApiService(SUBSCRIPTION_PLANS_ENDPOINT)
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setSubscriptions(response.records);
                setTotalRecords(response.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                showErrorMessage(dialogMessage, error);
                setIsLoading(false);
            });
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);

        fetchSubscriptions();
    };

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    const categoryTemplate = (rowData) => {
        return rowData?.shopCategory?.value;
    };

    const costTemplate = (rowData) => {
        return formatNumberWithCommas(rowData?.costPerSubscription);
    };

    const countryTempelate = (rowData) => {
        return rowData?.country?.name;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex justify-content-center align-items-center">
                <Button
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-text p-button-success"
                    onClick={() => {
                        setSelectedRecord(rowData);
                        setShowForm(true);
                    }}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-text p-button-danger"
                    onClick={() => {
                        setSelectedRecord(rowData);
                        setDeleteRecordDialog(true);
                    }}
                />
            </div>
        );
    };

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} records
                </span>
            );
        }
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 ">Subscription plans</h5>

            <div className="ml-auto flex gap-3">
                <Button label="Add" icon="pi pi-plus" className="primary-btn" onClick={(event) => setShowForm(true)} aria-controls="popup_menu_right" aria-haspopup />
            </div>
        </div>
    );

    const allowExpansion = (rowData) => {
        return rowData.description !== null && rowData.description !== undefined;
    };

    const rowExpansionTemplate = (rowData) => {
        return (
            <div className="field ml-6 pl-4">
                <div className="grid col-12">
                    {rowData.description && (
                        <div className="col-12 md:col-6 lg:col-6">
                            <label className="mt-3">
                                <span className="font-medium">Description:</span>
                                <p className="wrap">{rowData.description}</p>
                            </label>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    {header}
                    <DataTable
                        loading={isLoading}
                        ref={dt}
                        value={subscriptions}
                        dataKey="id"
                        rows={10}
                        className="datatable-responsive"
                        emptyMessage="No records found."
                        size="small"
                        expandedRows={expandedRows}
                        onRowToggle={(e) => setExpandedRows(e.data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        resizableColumns
                    >
                        <Column expander={allowExpansion} style={{ width: '4rem' }} />

                        <Column field="name" header="Name" />
                        <Column field="durationInDays" header="Duration (days)" className="text-center" />
                        <Column field="costPerSubscription" header="Cost" className="text-center" body={costTemplate} />
                        <Column field="category" header="Shop Category" className="text-center" body={categoryTemplate} />
                        <Column field="country" header="Country" className="text-center" body={countryTempelate} />
                        <Column field="recordStatus" header="Status" />

                        <Column field="createdByFullName" header="Created by" />

                        <Column body={actionBodyTemplate} header="Actions" className="text-center" headerStyle={{ textAlign: 'center' }} />
                    </DataTable>
                    <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} />

                    <SubscriptionPlanForm setShowForm={setShowForm} setSelectedRecord={setSelectedRecord} toast={toast} selectedRecord={selectedRecord} isVisible={showForm} refreshTable={fetchSubscriptions} />
                    <DeleteRecordDialog
                        isLoading={isLoading}
                        selectedRecordName={`${selectedRecord?.name} subscription record`}
                        setDeleteProductDialog={setDeleteRecordDialog}
                        deleteProductDialog={deleteRecordDialog}
                        setSelectedRecord={setSelectedRecord}
                        apiUrl={`${SUBSCRIPTION_PLANS_ENDPOINT}/${selectedRecord?.id}`}
                        setIsLoading={setIsLoading}
                        onComplete={() => {
                            fetchSubscriptions();
                            setSelectedRecord(null);
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Record deleted', life: 3000 });
                            setIsLoading(false);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPlans;
