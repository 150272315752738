import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { Messages } from 'primereact/messages';
import React, { useEffect, useRef, useState } from 'react';
import { convertToServerDate, formatNumberWithCommas, toReadableDate, toReadableDateTime, toReadableTime } from '../../utils/Utils';
import { BaseApiService } from '../../utils/BaseApiService';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function EditSaleDialog({ selectedSale, editSale, setEditSale, toast }) {
    const dialogMessage = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [soldOnDate, setSoldOnDate] = useState(null);

    const saveSale = () => {
        const { id, shopId, amountPaid, unitCost, lineItems } = selectedSale;

        let payload = { id, shopId, amountPaid, unitCost, lineItems, soldOnDate: convertToServerDate(soldOnDate) };

        let apiUrl = `/shop-sales/${selectedSale?.id}`;

        setIsLoading(true);
        new BaseApiService(apiUrl)
            .saveRequestWithJsonResponse(payload, true)
            .then((response) => {
                toast.current.show({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Sale details saved',
                    life: 3000
                });
                setIsLoading(false);
                setEditSale(false);
            })
            .catch((error) => {
                showErrorMessage(dialogMessage, error.message);
                setIsLoading(false);
            });
    };

    const productDialogFooter = (
        <div className="-mr-2">
            <Button
                loading={isLoading}
                label="Cancel"
                icon="pi pi-times"
                className=" outline-btn"
                onClick={() => {
                    setEditSale(false);
                    setIsLoading(false);
                }}
            />
            <Button loading={isLoading} label="Save" icon="pi pi-check" className=" primary-btn" onClick={saveSale} />
        </div>
    );

    const unitCostBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Unit cost</span>
                {formatNumberWithCommas(rowData?.unitCost)}
            </>
        );
    };

    const totalCostBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Total cost</span>
                {formatNumberWithCommas(rowData?.totalCost)}
            </>
        );
    };

    const nameBodyTemplate = (rowData) => {
        const { saleUnitName, shopProductName } = rowData;
        let unitName = saleUnitName ? ' - ' + saleUnitName : '';
        return (
            <>
                <span className="p-column-title">Item</span>
                {shopProductName + unitName}
            </>
        );
    };

    useEffect(() => {
        setSoldOnDate(new Date(selectedSale?.soldOnDate));
    }, [selectedSale]);

    return (
        <Dialog visible={editSale} onHide={() => setEditSale(false)} style={{ width: '700px' }} header={`Edit sale record `} modal className="p-fluid" footer={productDialogFooter}>
            <Messages ref={dialogMessage} style={{ width: '100%' }} />

            <div className="mt-2 flex justify-content-between">
                <div>
                    <span className="font-normal">{selectedSale?.shopName}</span>
                    <br />
                    <span className="text-sm">
                        <b>SN:</b> {selectedSale?.serialNumber}
                    </span>
                </div>

                <div className="flex gap-2">
                    <b>Sold on: </b>
                    <span className="text-right">
                        {toReadableDate(selectedSale?.soldOnDate)}
                        <br /> {toReadableTime(selectedSale?.dateCreated)}
                    </span>
                </div>
            </div>

            <DataTable value={selectedSale?.lineItems} dataKey="id" className="mt-5" emptyMessage="No items for sale found." size="small" resizableColumns>
                <Column field="shopProductName" header="Item" body={nameBodyTemplate} className="text-left"/>
                <Column field="quantity" header="Qty" className="text-center"/>
                <Column field="unitCost" body={unitCostBodyTemplate} header="Cost" className="text-right"/>
                <Column field="totalCost" body={totalCostBodyTemplate} header="Amount" className="text-right"/>
            </DataTable>

            <div className="px-2">
                <div className="flex justify-content-between mt-2">
                    <span className="font-semibold">Total</span>
                    <span className="font-normal">
                        {selectedSale?.currency} <span className="text-lg font-semibold">{formatNumberWithCommas(selectedSale?.totalCost)}</span>
                    </span>
                </div>
            </div>
            <div className="formgrid grid -mb-3 mt-3">
                <div className="field col">
                    <label>Amount paid</label>
                    <InputNumber inputClassName="text-right" value={selectedSale?.amountPaid} readOnly className="prevent-events" />
                </div>

                <div className="field col">
                    <label>Total Cost</label>
                    <InputNumber inputClassName="text-right" value={selectedSale?.totalCost} readOnly className="prevent-events" />
                </div>

                <div className="field col">
                    <label>Balance</label>
                    <InputNumber inputClassName="text-right" value={selectedSale?.balanceGivenOut} readOnly className="prevent-events" />
                </div>

                <div className="field col">
                    <label>Sold on</label>
                    <Calendar
                        inputClassName="text-center"
                        dateFormat="dd-M-yy"
                        value={soldOnDate}
                        onChange={(e) => {
                            setSoldOnDate(e.value);
                        }}
                    />
                </div>
            </div>

            <div className="px-0">
                <div className="flex justify-content-between mt-4">
                    <span className="font-semibold ">Served by:</span>
                    <span className=" font-normal">{selectedSale?.createdByFullName}</span>
                </div>

                <div className="flex justify-content-between mt-2">
                    <span className="font-semibold"> Edited by:</span>
                    <span className="font-normal">{selectedSale?.changedByFullName}</span>
                </div>

                <div className="flex justify-content-between mt-2">
                    <span className="font-semibold">Last changed:</span>
                    <span className=" font-normal">{toReadableDateTime(selectedSale?.dateChanged)}</span>
                </div>
            </div>
        </Dialog>
    );
}

export default EditSaleDialog;
