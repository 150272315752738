import { Button } from 'primereact/button';
import React from 'react';

export default function CongsComponent({ handleFinishButton }) {
    return (
        <>
            <div className="words-div">
                <h1 className='congsheader'>Congratulation!</h1>
            </div>
            <div className="congs-div">
                <p>Your account has been created successfully! Kindly wait for our team to verify your details and you will receive an email to access your account.</p>

                <div>
                    <Button onClick={handleFinishButton} className="send">
                        Finish
                    </Button>
                </div>
            </div>
        </>
    );
}
