import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import { BaseApiService } from '../../utils/BaseApiService';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Dropdown } from 'primereact/dropdown';
import { formatNumberWithCommas } from '../../utils/Utils';

import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Paginator } from 'primereact/paginator';
import StatusSummary from '../../components/StatusSummary';
import StorageParams from '../../constants/StorageParams';

const FinancialSummary = () => {
    const [financialRecords, setFinancialRecords] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [limit, setLimit] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    let offset = 0;

    const toast = useRef(null);
    const dt = useRef(null);

    const fetchSummary = () => {
        setIsLoading(true);
        let searchParameters = { offset: offset, searchTerm: searchTerm, limit: MAXIMUM_RECORDS_PER_PAGE, shopOwnerId: localStorage.getItem(StorageParams.IS_SHOP_OWNER) === 'true' ? localStorage.getItem(StorageParams.IS_SHOP_OWNER_ID) : '' };

        new BaseApiService(`/shop/owners`)
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
                setFinancialRecords(response.records);
                setTotalRecords(response?.totalItems);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchSummary();
    }, [searchTerm]);

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);
        fetchSummary()
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Financial Summary</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-right ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
                    <Button icon="pi pi-search" className="primary-btn" />
                </div>
            </span>
        </div>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} Financial Records
                </span>
            );
        }
    };

    const nameTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Owner name</span>
                {rowData.firstName + ' ' + rowData.lastName}
            </>
        );
    };
    const cashAtHandTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cash at hand</span>
                {formatNumberWithCommas(rowData.initialCapital)}
            </>
        );
    };
    const soldCapitalTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sold capital value</span>
                {formatNumberWithCommas(rowData?.performanceSummary?.totalSalesValue || 0)}
            </>
        );
    };

    const restockingTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Restocking</span>
                {formatNumberWithCommas(0)}
            </>
        );
    };
    const incomeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Income</span>
                {formatNumberWithCommas(rowData?.performanceSummary?.totalCapitalInvested || 0)}
            </>
        );
    };
    const expensesTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Expenses</span>
                {formatNumberWithCommas(rowData?.performanceSummary?.totalExpenses || 0)}
            </>
        );
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex justify-content-center actions align-items-center">
                <Button icon="pi pi-eye" className="p-button-rounded p-button-text p-button-success" />
                <Button icon="pi pi-print" className="p-button-rounded p-button-text p-button-info"  />
            </div>
        );
    };


    const netProfitTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Net Profit</span>
                {formatNumberWithCommas(0)}
            </>
        );
    };
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                    <StatusSummary title="Shops" value={totalRecords} />
                        <StatusSummary title="Stock" value="0" iconBg="bg-orange-100" iconName="pi-map-marker" iconColor=" text-orange-500" />
                        <StatusSummary title="Income" value="0" iconName="pi-inbox" iconColor="text-cyan-500" />
                        <StatusSummary title="Profit" value="0" />
                    </div>
                </div>

                <div className="card">
                    <Toast ref={toast} />

                    <DataTable
                        ref={dt}
                        loading={isLoading}
                        value={financialRecords}
                        dataKey="id"
                        rows={10}
                        
                        className="datatable-responsive"
                        emptyMessage="No records found."
                        header={header}
                        
                        size="small"
                        resizableColumns
                    >
                        <Column field="name" header="Owner " body={nameTemplate} className="text-left" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'center' }}/>
                        <Column field="totalShops" header="Shops"  className="text-center" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'right' }}/>
                        <Column field="initialCapital" header="Cash at hand" body={cashAtHandTemplate} className="text-right" headerStyle={{ width: '15%', minWidth: '10rem', textAlign: 'right' }}/>
                        <Column field="phone" header="Sold capital value" body={soldCapitalTemplate} className="text-right" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'center' }}/>
                        <Column field="distributors" header="Restocking" body={restockingTemplate} className="text-right" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'center' }}/>
                        <Column field="products" header="Income" body={incomeTemplate} className="text-right" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'center' }}/>
                        <Column field="createdBy" header="Expenses" body={expensesTemplate} className="text-right" headerStyle={{ width: '20%', minWidth: '10rem', textAlign: 'right' }}/>
                        <Column field="dateCreated" header="Profit" body={netProfitTemplate} className="text-right" headerStyle={{ width: '15%', minWidth: '10rem', textAlign: 'center' }}/>
                        <Column header='Actions' body={actionBodyTemplate} headerStyle={{ width: '15%', minWidth: '10rem', textAlign: 'center' }}/>
                    </DataTable>
                    <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={true} onPageChange={onPageChange} />
                </div>
            </div>
        </div>
    );
};
export default FinancialSummary;
