import { BASE_URL_ENDPOINT_PATH } from '../utils/EndpointsUtils';

export const STORAGE_URL = 'https://storage.bunnycdn.com/materialdesignnative';
export const ACCESS_KEY = 'ba8dd74c-5844-4d83-8523b4a5f50d-a128-4190';
export const BASE_URL = BASE_URL_ENDPOINT_PATH;
export const REMOTE_BASE_URL = BASE_URL;
export const REMOTE_WEB_URL = BASE_URL;
export const WEB_URL = 'https://backend.duqact.com/';
export const MAXIMUM_RECORDS_PER_PAGE = 10;

export const PAGINATION_SIZE_OPTIONS = [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 }
];

export const productionStatuses = [
    { name: 'Active', value: 1 },
    { name: 'Paused', value: 2 },
    { name: 'Stopped', value: 3 }
];

export const paymentMethods = [
    { name: 'Cash', id: 0 },
    { name: 'Credit', id: 1 }
];
export const genderOptions = [
    { name: 'Male', id: 0 },
    { name: 'Female', id: 1 },
    { name: 'Rather not say', id: 2 }
];

export const months = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' }
];

export const roleTypes = [{ value: 'SystemAdmins' }, { value: 'ShopManagers' }];
