let resolveBackendEndpoint = 'https://backend.duqact.com/api/v1';
//let resolveBackendEndpoint = 'http://localhost:8080/api/v1';

if (window.location.hostname.includes('duqact.com')) {
    resolveBackendEndpoint = 'https://backend.duqact.com/api/v1';
}

export const BASE_URL_ENDPOINT_PATH = resolveBackendEndpoint;

export const CURRENCIES_END_POINT = '/currencies';

export const LOOK_UPS_ENDPOINT = '/lookups/lookup-values';

export const SHOP_OWNERS_ENDPOINT = '/shop/owners';

export const SHOPS_ENDPOINT = '/shops';

export const SUBSCRIPTION_PLANS_ENDPOINT = '/subscription-plans';

export const COUNTRIES_ENDPOINT = '/lookups/countries';

export const SUBSCRIPTIONS_ENDPOINT = '/subscriptions';

export const SUBSCRIPTION_ATTATCHMENT_ENDPOINT = '/subscriptions/attach';

export const SUBSCRIPTION_PAYMENT_ENDPOINT = '/subscriptions/payments';
