import { Dialog } from 'primereact/dialog';
import React, { useState, useEffect, useRef } from 'react';
import { BaseApiService } from '../../utils/BaseApiService';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { convertToServerDate, formatNumberWithCommas } from '../../utils/Utils';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { Messages } from 'primereact/messages';
import RenderCurrency from '../RenderCurrency';

function SalePayment({ viewSalePayment, setViewSalePayment, selectedSale, refresh }) {
    const [paymentDate, setPaymentDate] = useState(new Date());
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const [amount, setAmount] = useState(null);

    const dialogMessage = useRef();

    const saveSale = async () => {
        setSubmitted(true);

        let payLoad = {
            id: 0,
            creditSaleId: selectedSale?.sale?.id,
            amount: amount,
            paymentDate: convertToServerDate(paymentDate)
        };

        const isValidSubmision = true;

        if (isValidSubmision) {
            setLoading(true);

            new BaseApiService(`/credit-sales/${selectedSale?.id}/payments`)
                .postRequest(payLoad)
                .then((d) => d.json())
                .then(async (response) => {
                    setLoading(false);
                    hideDialog();
                    refresh();
                })
                .catch((error) => {
                    showErrorMessage(dialogMessage, error?.message);
                    setLoading(false);
                });
        }
    };

    const hideDialog = () => {
        setSubmitted(false);
        setLoading(false);
        setViewSalePayment(false);
        setAmount(null);
    };

    const productDialogFooter = (
        <>
            <Button loading={isLoading} label="Cancel" icon="pi pi-times" className=" outline-btn" onClick={hideDialog} />
            <Button loading={isLoading} label="Save" icon="pi pi-check" className="primary-btn" onClick={saveSale} />
        </>
    );

    return (
        <Dialog footer={productDialogFooter} header={'Enter payment details'} visible={viewSalePayment} className={'p-fluid'} style={{ width: '500px' }} modal onHide={hideDialog}>
            <Messages ref={dialogMessage} style={{ width: '100%' }} />
            <div className="field">
                <h6>
                    Outstanding balance : <RenderCurrency value={selectedSale?.amountLoaned - selectedSale?.amountRepaid} currencySymbol={selectedSale?.currency} />
                </h6>
                <label>Amount {selectedSale?.currency && `(${selectedSale?.currency})`}</label>
                <InputNumber autoFocus onValueChange={(e) => setAmount(e.value)} value={amount} />
                {submitted && amount > selectedSale?.balance && <span className="p-error">Amount should not exceed {formatNumberWithCommas(selectedSale?.balance)}</span>}
            </div>

            <div className="field">
                <label>Payment date</label>
                <Calendar value={paymentDate} onChange={(e) => setPaymentDate(e.value)} dateFormat="dd-M-yy" />
            </div>
        </Dialog>
    );
}

export default SalePayment;
