import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { BaseApiService } from '../../utils/BaseApiService';
import { showErrorMessage } from '../../constants/ErrorMessages';
import { convertDateFormat, formatNumberWithCommas, toReadableDate, toReadableDateTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import { MAXIMUM_RECORDS_PER_PAGE } from '../../constants/Constants';
import { Paginator } from 'primereact/paginator';
import { TabPanel, TabView } from 'primereact/tabview';
import DeleteRecordDialog from '../../components/dialogs/DeleteRecordDialog';
import StockEntryForm from '../../components/dialogs/StockEntryForm';
import StatusSummary from '../../components/StatusSummary';
import FilterComponent from '../../components/FilterComponent';

const ProductReports = () => {
    const [products, setProducts] = useState([]);
    const [stockEntryDialog, setStockEntryDialog] = useState(false);
    const [deleteStockEntryDialog, setDeleteStockEntryDialog] = useState(false);
    const [selectedStockEntries, setSelectedStockEntries] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [limit, setLimit] = useState(0);
    const [isUploadDialogVisible, setIsUploadDialogVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [shops, setShops] = useState([]);
    const [selectedShop, setSelectedShop] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [endDate, setEndDate] = useState(null);
    const [productDetails, setProductDetails] = useState([]);
    let offset = 0;

    const dialogMessage = useRef();
    const toast = useRef(null);
    const dt = useRef(null);
    const isShopOwner = UserSessionUtils.getShopOwner();
    const isShopAttendant = UserSessionUtils.getShopAttendant();


    const fetchShops = async () => {
        setIsLoading(true);
        const searchParameters = { 
            offset: 0,
            limit: 0,
            ...(isShopOwner && { shopOwnerId: UserSessionUtils.getShopOwnerId() }) 
        };
        new BaseApiService('/shops')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                if (response?.records?.length > 1) {
                    setShops([...response?.records.reverse()]);
                    return;
                }
                setShops(response.records);
                setIsLoading(false);
            })
            .catch((error) => {});
    };


    const fetchProducts = async () => {
        setIsLoading(true);
        let searchParameters = {
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            ...(isShopOwner && shops?.length < 2 && { shopId: shops[0]?.id }),
            ...(isShopAttendant && { shopId: UserSessionUtils.getShopId() }),
            ...(searchTerm && { searchTerm: searchTerm }),
            ...(selectedShop && { shopId: selectedShop?.id }),
            ...(startDate && { startDate: convertDateFormat(startDate) }),
            ...(endDate && { endDate: convertDateFormat(endDate, true) }),
        };

        if(UserSessionUtils.getSuperAdmin()){
            searchParameters = {
                offset: offset,
                limit: MAXIMUM_RECORDS_PER_PAGE,
                ...({shopId: shops[25]?.id}),
                ...(searchTerm && { searchTerm: searchTerm }),
                ...(selectedShop && { shopId: selectedShop?.id }),
                ...(startDate && { startDate: convertDateFormat(startDate) }),
                ...(endDate && { endDate: convertDateFormat(endDate, true) }),
            };
        }

        if(UserSessionUtils.getShopOwner()){
            searchParameters = {
                offset: offset,
                limit: MAXIMUM_RECORDS_PER_PAGE,
                ...({shopId: shops[0]?.id}),
                ...(shops?.length > 1 && {shopId: shops[activeIndex]?.id}),
                ...(searchTerm && { searchTerm: searchTerm }),
                ...(selectedShop && { shopId: selectedShop?.id }),
                ...(startDate && { startDate: convertDateFormat(startDate) }),
                ...(endDate && { endDate: convertDateFormat(endDate, true) }),
            };
        }

        new BaseApiService('/reports/product-stock-info')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setProducts(response);
                setTotalRecords(response.length);

                setIsLoading(false);
            })
            .catch((error) => {
                showErrorMessage(dialogMessage, error);
                setIsLoading(false);
            });
    };

    const fetchProductDetails = async () => {
        setIsLoading(true);
        let searchParameters = {
            offset: offset,
            limit: MAXIMUM_RECORDS_PER_PAGE,
            ...(isShopOwner && { shopOwnerId: UserSessionUtils.getShopOwnerId() }),
            ...(isShopAttendant && { shopId: UserSessionUtils.getShopId() }),
            ...(searchTerm && { searchTerm: searchTerm }),
            ...(selectedShop && { shopId: selectedShop?.id }),
            ...({ shopId: shops[activeIndex]?.id })
        };

        new BaseApiService('/shop-products')
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setProductDetails(response.records);
                setIsLoading(false);
            })
            .catch((error) => {
                showErrorMessage(dialogMessage, error);
                setIsLoading(false);
            });
    };

    const onPageChange = (e) => {
        offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
        setFirst(e.first);
        setLimit(MAXIMUM_RECORDS_PER_PAGE);

        fetchProducts();
    };

    const clearFilterFields = () => {
        setSearchTerm(null);
        setSelectedShop(null);
        setEndDate(null);
        setStartDate(null);
    };

    useEffect(() => {
        fetchShops();
    }, []);

    useEffect(() => {
        fetchProducts();
        fetchProductDetails()
    }, [shops, activeIndex]);

    const header = (
        <div className="flex justify-content-between md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Sales by products</h5>

            <Button icon={<i class="bx bx-dots-vertical-rounded" style={{ 'font-size': '1.5rem' }}></i>} className="bg-white border-0 p-2 hover:bg-gray-200 " />
        </div>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} sales by products
                </span>
            );
        }
    };

    const amountBodyTemplate = (rowData) => {
        return (
            <>
                <span >{formatNumberWithCommas(rowData.amount)}</span>
            </>
        );
    };

    const allowExpansion = (rowData) => {
        return rowData !== null && rowData !== undefined;
    };

    const dynamicTabs = () => {
        if (shops?.length > 1 && !UserSessionUtils.getSuperAdmin()) {
            return (
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="h-3rem">
                    {shops.map((shop, index) => (
                        <TabPanel key={index} header={shop.name} className="h-auto"></TabPanel>
                    ))}
                </TabView>
            );
        }
    };

    const handleDayFilter = () => {
        //         console.log(startDate)

        // setStartDate(new Date());
        // setEndDate(new Date());
        // console.log(startDate)
        // fetchProducts();
    }
    const handleMonthFilter = () => {}
    const handleYearFilter = () => {}

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Purchases" value={formatNumberWithCommas(totalRecords)} />
                        <StatusSummary title="Amount" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Available" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Depleted" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div>
                </div>

                <FilterComponent onFilter={fetchProducts} onClear={clearFilterFields}>
                    <div className="formgrid grid">
                        {!isShopAttendant && shops?.length > 1 && (
                            <div className="field col-4">
                                <label htmlFor="shop">Shop</label>
                                <Dropdown id="shop" value={selectedShop} options={shops} onChange={(e) => setSelectedShop(e.target.value)} optionLabel={'name'} filter filterBy="name" placeholder="Select Shop" />
                            </div>
                        )}

                        <div className="field col-4">
                            <label htmlFor="shop">Start Date</label>
                            <Calendar className="w-full" maxDate={endDate ? endDate : new Date()} value={startDate} onChange={(e) => setStartDate(e.value)} placeholder="Start date" dateFormat="dd-M-yy" />
                        </div>

                        <div className="field col-4">
                            <label htmlFor="shop">End Date</label>
                            <Calendar className="w-full" maxDate={new Date()} value={endDate} minDate={startDate ? startDate : new Date()} onChange={(e) => setEndDate(e.value)} placeholder="End date" dateFormat="dd-M-yy" />
                        </div>
                    </div>
                </FilterComponent>

                <div className="card">
                    <Toast ref={toast} />

                    {header}

                    <div className=" justify-content-end flex md:flex-row md:align-items-center mt-5">
                        {/* <span className="block mt-2 md:mt-0 p-input-icon-left">
                            <div className="p-inputgroup flex-1">
                                <InputText placeholder="Search" value={searchTerm} onInput={(e) => setSearchTerm(e.target.value)} />
                                <Button icon="pi pi-search" className="primary-btn" onClick={fetchProducts} />
                            </div>
                        </span> */}
                        {/* <div className="my-2 ml-auto flex flex-column md:flex-row md:align-items-center">
                            <Button label="Upload" icon="pi pi-upload" className="outline-btn mr-2" onClick={() => setIsUploadDialogVisible(true)} />
                            <Button label="Template" icon="pi pi-download" className="d-btn mr-2" />
                            <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
                        </div> */}
                        {/* <span className=" p-buttonset">
                            <Button label="D" onClick={handleDayFilter} className=' primary-btn' />
                            <Button label="M" onClick={handleMonthFilter} className=' primary-btn' />
                            <Button label="Y" onClick={handleYearFilter} className=' primary-btn' />
                        </span> */}
                    </div>

                    <DataTable
                        loading={isLoading}
                        ref={dt}
                        value={products}
                        selection={selectedStockEntries}
                        onSelectionChange={(e) => setSelectedStockEntries(e.value)}
                        dataKey="id"
                        paginator rows={12} rowsPerPageOptions={[10, 20, 30, 50]} 
                        className="datatable-responsive"
                        emptyMessage="No sales by products found."
                        header={dynamicTabs}
                        size="small"
                        resizableColumns
                    >

                        <Column field="name" header="Product" style={{ maxWidth: '10%' }} />
                        <Column field="purchasedQuantity" header="Opening Stock" className="text-center" />

                        <Column field="itemsSold" header="Sold Items " className="text-center" headerStyle={{ textAlign: 'center' }} />

                        <Column field="" header="Amount Sold" className="text-center" body={amountBodyTemplate} headerStyle={{ textAlign: 'right' }}  />
                        <Column field="inStock" header="In Stock Items" className="text-center" headerStyle={{ textAlign: 'right' }}/>
                    </DataTable>
                    {/* <Paginator template={template2} first={first} rows={MAXIMUM_RECORDS_PER_PAGE} totalRecords={totalRecords} alwaysShow={false} onPageChange={onPageChange} /> */}

                    <StockEntryForm
                        onSave={() => {
                            fetchProducts();
                            setSelectedRecord(null);
                            setStockEntryDialog(false);
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Stock details saved', life: 3000 });
                        }}
                        selectedRecord={selectedRecord}
                        setStockEntryDialog={setStockEntryDialog}
                        stockEntryDialog={stockEntryDialog}
                        shops={shops}
                    />

                    <DeleteRecordDialog
                        isLoading={isLoading}
                        selectedRecordName={`${selectedRecord?.productName} purchase record?`}
                        setDeleteProductDialog={setDeleteStockEntryDialog}
                        deleteProductDialog={deleteStockEntryDialog}
                        setSelectedRecord={setSelectedRecord}
                        showForm={true}
                        apiUrl={'/stock-entries/' + selectedRecord?.id}
                        setIsLoading={setIsLoading}
                        onComplete={() => {
                            fetchProducts();
                            setSelectedRecord(null);
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Purchase record deleted', life: 3000 });
                            setIsLoading(false);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductReports;