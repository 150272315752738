import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { BaseApiService } from '../../utils/BaseApiService';
import { Dropdown } from 'primereact/dropdown';
import { formatNumberWithCommas, toReadableDateTime } from '../../utils/Utils';
import { UserSessionUtils } from '../../utils/UserSessionUtils';
import StatusSummary from '../../components/StatusSummary';
import { SHOPS_ENDPOINT } from '../../utils/EndpointsUtils';
import ShopDetailsForm from '../../components/dialogs/ShopDetailsForm';

const ShopsList = () => {
    const [shops, setShops] = useState(null);
    const [shopDialog, setShopDialog] = useState(false);
    const [deleteShopDialog, setDeleteShopDialog] = useState(false);
    const [shop, setShop] = useState(null);
    const [loading, setLoading] = useState(false);

    const toast = useRef(null);
    const dt = useRef(null);

    const isAdmin = UserSessionUtils.getSuperAdmin();

    const fetchShops = async () => {
        let searchParameters = { searchTerm: '', offset: 0, limit: 0 };

        if (UserSessionUtils.getShopOwner()) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
        }
        await new BaseApiService(SHOPS_ENDPOINT)
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
                setShops(response.records);
                const offersDebt = [...response.records].some((shop) => shop?.supportsCreditSales === true);
                UserSessionUtils.setOffersDebt(offersDebt);
            })
            .catch((error) => {});
    };

    useEffect(() => {
        fetchShops();
    }, []);

    const openNew = () => {
        setShop(null);
        setShopDialog(true);
    };

    const hideDialog = () => {
        setShopDialog(false);
        setShop(null);
        setLoading(false);
    };

    const hideShopDialog = () => {
        setDeleteShopDialog(false);
    };

    const editShop = (shop) => {
        setShop({ ...shop });
        setShopDialog(true);
    };

    const confirmDeleteShop = (shop) => {
        setShop(shop);
        setDeleteShopDialog(true);
    };

    const deleteShop = () => {
        new BaseApiService(`${SHOPS_ENDPOINT}/${shop.id}`)
            .deleteRequestWithJsonResponse()
            .then((response) => {
                let _shops = shops.filter((val) => val.id !== shop.id);
                setShops(_shops);
                setDeleteShopDialog(false);
                setShop(null);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Shop Deleted', life: 3000 });
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const dateCreatedBodyTemplate = (rowData) => {
        return <>{toReadableDateTime(rowData.dateCreated)}</>;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex md:flex-row md:align-items-center justify-content-center">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text p-button-success " onClick={() => editShop(rowData)} />
                {shops?.length > 1 && <Button icon="pi pi-trash" className="p-button-rounded p-button-text p-button-danger mt-2" onClick={() => confirmDeleteShop(rowData)} />}
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center">
            <h5 className="m-0 line relative">Shops</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
                <div className="p-inputgroup flex-1">
                    <InputText placeholder="Search" />
                    <Button icon="pi pi-search" className="primary-btn" onClick={fetchShops} />
                </div>
            </span>
            <div className="my-2 ml-auto">
                <Button label="New" icon="pi pi-plus" className="primary-btn mr-2" onClick={openNew} />
            </div>
        </div>
    );

    const deleteShopDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideShopDialog} />
            <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteShop} />
        </>
    );

    const template2 = {
        layout: 'CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 }
            ];

            return (
                <React.Fragment>
                    <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}></span>
                    <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
                    <span className="mr-auto"></span>
                </React.Fragment>
            );
        },
        CurrentPageReport: (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: 'auto', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} shops
                </span>
            );
        }
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="py-2">
                    <div className="grid">
                        <StatusSummary iconName="pi pi-shopping-cart " title="Shops" value={formatNumberWithCommas(0)} />
                        <StatusSummary title="Attendants" value={formatNumberWithCommas(0)} iconName="bx bx-receipt" />
                        <StatusSummary title="Capital" value={formatNumberWithCommas(Math.round(0))} iconName="pi pi-money-bill" />
                        <StatusSummary title="Removed Capital" value={formatNumberWithCommas(0)} iconName="bx bx-wallet" />
                    </div>
                </div>

                <div className="card">
                    <Toast ref={toast} />

                    <DataTable ref={dt} loading={loading} value={shops} dataKey="id" paginator rows={10} className="datatable-responsive" paginatorTemplate={template2} emptyMessage="No shop found." header={header}>
                        <Column field="name" header="Name" />
                        <Column field="physicalLocation" header="Physical location" />
                        <Column field="registrationNumber" header="Registration Number" />
                        <Column field="categoryName" header="Category" />
                        <Column field="ownerName" header="Owner" />
                        <Column field="changedByFullName" header="Created by" />
                        <Column field="dateCreated" header="Created on" body={dateCreatedBodyTemplate} />
                        {isAdmin && <Column body={actionBodyTemplate} header="Actions" className="text-center" />}
                    </DataTable>

                    <ShopDetailsForm
                        visible={shopDialog}
                        selectedShop={shop}
                        hideDialog={hideDialog}
                        onComplete={() => {
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Shop Details Saved', life: 3000 });
                            fetchShops();
                        }}
                    />

                    <Dialog visible={deleteShopDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteShopDialogFooter} onHide={hideShopDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {shop && (
                                <span>
                                    Are you sure you want to delete <b>{shop.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};
export default ShopsList;
